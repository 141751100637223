/*
*
* Сделано в студии AppFox
*
*/
<template>
  <div class="file-wrapper">
    <div v-if="isImage" class="file-image-wrap">
      <img :alt="file.name" class="file-image" :src="image" />
    </div>

    <div v-else class="file-doc-wrap">
      <div class="file-doc" />
    </div>

    <div class="file-title" :title="file.name">{{ file.name }}</div>

    <div class="file-remove-btn" @click="$emit('remove')">
      <div class="file-remove-btn-icon" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      required: true,
      validator: function (value) {
        return value instanceof File;
      },
    },
  },

  computed: {
    image() {
      return URL.createObjectURL(this.file);
    },

    isImage() {
      return this.file.type.split("/")[0] == "image";
    },
  },
};
</script>

<style lang="scss" scoped>
.file {
  &-wrapper {
    width: 146px;
    height: 141px;
    background-color: #f7f7f7;
    position: relative;
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 22px;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;

    &-wrap {
      padding: 10px;
      width: 100%;
      height: 122px;
      border-radius: 5px 5px 0 0;
    }
  }

  &-doc {
    width: 100%;
    height: 100%;

    &-wrap {
      padding: 13px 37px;
      width: 100%;
      height: 122px;
      border-radius: 5px 5px 0 0;
    }
  }

  &-title {
    padding: 0 5px 5px;
    font-size: 12px;
    line-height: 14px;
    color: #1a1f21;
    border-radius: 0 0 5px 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-remove-btn {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background-color: green;
    position: absolute;
    top: -5px;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-icon {
      width: 14px;
      height: 14px;
    }
  }
}
</style>

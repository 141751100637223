<template>
  <div>
    <div
      :class="[
        'message-wrap',
        {
          self: isMyMessage,
        },
      ]"
    >
      <div v-if="showTimeSeparator" class="time-separator">
        {{ message.time }}
      </div>

      <!-- текстовое смс -->
      <div class="message-text-wrap">
        <div
            class="message-text"
        >
          {{ message.message }}
          <div
            v-if="message.files && message.files.image"
            class="message-media"
          >
            <template
              v-for="file in message.files.image"
            >
              <img
                :key="file.id"
                :src="file.url"
                alt="image"
                class="message-media"
                @click="openModalImage(file.url)"
              />
            </template>
          </div>
          <div
            v-if="message.files && message.files.doc"
            class="message-media"
          >
            <div
              v-for="file in message.files.doc"
              :key="file.id"
            >
              <a :href="file.url" target="_blank" download >
                {{ file.name }}
                <img
                  src="@/assets/img/doc-icon.svg"
                  alt="image"
                  class="message-media"
                />
              </a>
            </div>
          </div>
          <div
            v-if="message.files && message.files.video"
            class="message-media"
          >
            <div
              v-for="file in message.files.video"
              :key="file.id"
            >
              <video
                class="message-media"
                controls="controls"
              >
                  <source
                    :src="file.url"
                  >
              </video>
            </div>
          </div>


          <ProductCard
            v-for="product in message.products"
            :product="product"
          />
        </div>
      </div>

    </div>
    <ModalImage
      v-if="showImageModal"
      :image="imageForModal"
      @close="showImageModal = false"
    />
  </div>
</template>

<script>
import ModalImage from "@/components/Modals/ModalImage";
import ProductCard from "@/components/Chat/ProductCard.vue";

export default {
  components: {
      ProductCard,
    ModalImage,
  },

  props: {
    isFirst: {
      type: Boolean,
      required: true,
    },

    message: {
      type: Object,
      required: true,
    },

    nextHasSameSender: {
      type: Boolean,
    },

    showDateSeparator: {
      type: Boolean,
    },

    showTimeSeparator: {
      type: Boolean,
    },
  },

  data() {
    return {
      previewURL: "https://docs.google.com/viewer?url=",
      showImageModal: false,
      imageForModal: "",
    };
  },

  computed: {
    isMyMessage() {
      return !!this.message.admin;
    },
  },

  methods: {
    getFilePath(shortUrl) {
      return process.env.API_URL + "/storage/" + shortUrl;
    },

    openModalImage(src) {
      this.showImageModal = true;
      this.imageForModal = src;
    },

    getFilename(shortUrl) {
      return shortUrl.split("/").pop();
    },
  },
};
</script>

<style lang="scss" scoped>
.date-separator {
  position: relative;
  height: 1px;
  width: 100%;
  background-color: #cee8f5;
  margin: 38px 0;

  &.is-first {
    margin-top: 0;
  }

  .date {
    padding: 0 10px;
    font-size: 14px;
    line-height: 16px;
    color: #3f6271;
    background-color: #e9f4f9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.message-wrap {
  position: relative;

  .time-separator {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  .message-text {
    width: 448px;
    padding: 15px;
    font-size: 14px;
    line-height: 16px;
    color: #1a1f21;
    position: relative;
    word-break: break-all;

    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
    }
  }

  &.self {
    &.has-time-separator {
      padding-top: 13px;

      &.without-text {
        padding-top: 30px;
      }
    }

    .time-separator {
      background-color: #26abe1;
      padding: 5px 8px;
      border-radius: 10px;
      color: #ffffff;
      position: absolute;
      top: 0;
      right: 15px;
      z-index: 1;
    }

    .message-text-wrap {
      display: flex;
      justify-content: flex-end;
    }

    .message-text {
      background-color: #d7f2ff;
      padding-left: 17px;
      border-radius: 5px 5px 0 5px;

      &::before {
        border-top: 5px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid #d7f2ff;
        border-left: 4px solid #d7f2ff;
        right: 0;
        transform: translateX(5px);
      }
    }

    .message-document {
      background-color: #d7f2ff;

      &-wrap {
        display: flex;
        justify-content: flex-end;
      }
    }

    .message-media-wrap {
      display: flex;
      justify-content: flex-end;
    }
  }

  &:not(.self) {
    .time-separator {
      background-color: #e9f4f9;
      padding: 0 0 10px 21px;
      color: black;
    }

    .message-text {
      background-color: #ffffff;
      padding-left: 17px;
      border-radius: 5px 5px 5px 0;

      &::before {
        border-top: 5px solid transparent;
        border-left: 4px solid transparent;
        border-right: 4px solid #ffffff;
        border-bottom: 6px solid #ffffff;
        left: 0;
        transform: translateX(-5px);
      }
    }

    .message-document {
      background-color: #ffffff;
    }
  }

  &.next-has-same-sender {
    margin-bottom: 10px;
  }

  &:not(.next-has-same-sender) {
    margin-bottom: 15px;
  }
}

.message-document {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 7px 10px;
  border-radius: 3px;

  &-wrap {
    &.has-mt {
      margin-top: 10px;
    }
  }

  &-info {
    font-size: 14px;
    line-height: 16px;

    &-title {
      color: #2a2c31;
      font-weight: 700;
      margin-bottom: 3px;
    }

    &-size {
      color: #626262;
    }
  }
}

.message-media {
  width: 183px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  margin-top: 5px;

  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: rgba(211, 211, 211, 0.2);
      cursor: pointer;
    }
  }

  &-wrap {
    &.has-mt {
      margin-top: 10px;
    }
  }

  &-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 25px;
    color: #ffffff;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 1;
  }
}
</style>

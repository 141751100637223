<template>
  <div class="d-flex chat-wrapper">
<!--    <section class="chat-sidebar">-->
<!--      <div class="vuebar-element">-->
<!--        <div class="rooms">-->
<!--          <ChatContacts :set-active-contact="activeContact = $event" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <ChatMessages
      :contact-active="activeContact"
    />
  </div>
</template>

<script>
import ChatMessages from "./ChatMessages";

export default {
  components: {
    ChatMessages
  },

  data() {
    return {
      activeContact: {},
    }
  }
}
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
}

.chat-wrapper {
  padding-top: 15px;
  max-height: 100%;
}

.chat-page {
  padding-bottom: 39px;

  .chat-sidebar {
    width: 333px;
    height: 680px;
    margin-right: 7px;

    .rooms {
      display: flex;
      flex-direction: column;
    }

    .vuebar-element {
      height: 680px;
      overflow: auto;
      position: relative;
      padding-top: 52px;

      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
        background-color: #f7f7f7;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 5px solid #cee8f5;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #cee8f5;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }

      .search-group {
        margin-bottom: 15px;
        position: absolute;
        left: 0;
        right: 8px;
        top: 0;
        z-index: 1;

        .input {
          height: 37px;
          padding: 11px 36px 10px 11px;
          border: none;
          background-color: #e4f7ff;
          color: #3f6271;

          &::placeholder,
          &::-webkit-input-placeholder,
          &::-ms-input-placeholder,
          &:-ms-input-placeholder {
            color: #3f6271 !important;
            font-family: "Roboto", sans-serif;
            font-size: 14px;
          }

          &:focus {
            outline: none !important;
          }
        }
      }
    }
  }
}
</style>

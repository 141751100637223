<template>
    <transition name="modal-fade">
        <div ref="modal" class="modal-backdrop" @click="clickOutside">
            <div
                v-if="products.data && products.data.length"
                class="modal" role="dialog">
                <div class="btn-close" @click="close">
                    <md-icon>close</md-icon>
                </div>
                <ProductCard
                    v-for="product in products.data"
                    :product="product"
                    :class="`${productsActive.includes(product.id) ? 'active' : ''}`"
                    @toggleActive="product => toggleActive(product)"
                />
                <div v-if="products.meta.last_page > 1" class="pagination">
                    <md-button
                        class="md-primary"
                        :disabled="products.meta.current_page === 1"
                        @click="$emit('prev')"
                    >
                        <md-icon>navigate_before</md-icon>
                    </md-button>
                    <div class="pages">
                        <span>{{ products.meta.current_page }}</span>
                        /
                        <span>{{ products.meta.last_page }}</span>
                    </div>
                    <md-button
                        class="md-primary"
                        :disabled="products.meta.current_page === products.meta.last_page"
                        @click="$emit('next')"
                    >
                        <md-icon>navigate_next</md-icon>
                    </md-button>
                </div>
                <div>
                    <md-button
                        class="md-primary"
                        @click="handleSubmit"
                    >
                        Отправить {{ productsActive.length }}
                    </md-button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {formatISODate} from "@/helpers/formatDate";
import ProductCard from "@/components/Chat/ProductCard.vue";

export default {
    components: {ProductCard},

    props: {
        id: Number,
    },

    computed: {
        ...mapState("products", ["products"]),
    },
    data() {
        return {
            productsActive: [],
        };
    },

    async mounted() {
        await this.searchProducts({
            sortBy: "id",
            page: 1,
            sortOrder: "asc",
        });
    },

    methods: {
        ...mapActions("products", ["searchProducts"]),
        ...mapActions("chats", ["sendMessage"]),
        handleSubmit() {
            this.sendMessage({
                id: this.id,
                product_ids: this.productsActive,
            }).then(() => this.$emit("close"));
        },
        toggleActive(product) {
          if(this.productsActive.includes(product.id)) {
           this.productsActive.splice(this.productsActive.findIndex(i => i === product.id), 1);
          } else {
            this.productsActive.push(product.id);
          }
        },
        clickOutside(e) {
            if (e.target.classList.contains("modal-backdrop")) {
                this.$emit("close");
            }
        },
        close() {
            this.$emit("close");
        },
        getCategories(categories) {
            return categories
                .flat()
                .map((item) => item.category.name)
                .join(", ");
        },
        getDate(isoDate) {
            return formatISODate(isoDate);
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;

  .modal {
    position: relative;
    height: auto;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;
    min-width: 500px;
    max-width: 900px;
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.title-wrap {
  .md-title,
  .md-headline,
  .md-body-1 {
    text-align: center;
  }

  .md-headline {
    position: relative;

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(calc(100% + 10px), -50%);
      cursor: pointer;
      display: flex;
    }
  }

  .subheading-wrap {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  .description-wrap {
    margin-top: 10px;
  }
}

.images-wrap {
  margin: 20px 0;
  justify-content: center;
  align-items: center;
}

.options {
  align-items: center;
}

.params {
  justify-content: center;
  width: 100%;
}

.categories {
  margin-top: 15px;
}

.row,
.column {
  display: flex;
}

.row {
  justify-content: space-between;
}

.column {
  flex-direction: column;

  .md-subheading {
    margin-top: 15px;
  }
}
</style>

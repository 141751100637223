<template>
    <md-table>
      <md-table-row>
          <md-table-cell md-label="ID" class="custom-field">
              <span>Изображения товара:</span>
          </md-table-cell>
          <md-table-cell md-label="ID" class="custom-field">
              <div
                      v-for="i in item.product.images"
                      :key="i"
              >
                  <img
                          :src="i.original_url || require('@/assets/img/new_logo.png')"
                          alt="Аватар"
                  />
              </div>
              <img
                  v-if="item.product.image"
                  :src="item.product.image"
                  alt="Аватар"
              />
          </md-table-cell>
      </md-table-row>
      <md-table-row>
        <md-table-cell md-label="ID" class="custom-field">
          <span>Наименование товара:</span>
        </md-table-cell>
        <md-table-cell md-label="ID" class="custom-field">
          <span>{{ item.product.name }}</span>
        </md-table-cell>
      </md-table-row>
        <template
          v-if="item.product_option || item.option"
        >
            <md-table-row>
                <md-table-cell md-label="ID" class="custom-field">
                    <span>Цвет: </span>
                </md-table-cell>
                <md-table-cell md-label="ID" class="custom-field">
                    <span>{{ color ? color : colorName(item[item.product_option ? "product_option" : "option"].color_id) }}</span>
                </md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-cell md-label="ID" class="custom-field">
                    <span>Размер товара: </span>
                </md-table-cell>
                <md-table-cell md-label="ID" class="custom-field">
                    <span>{{ item[item.product_option ? "product_option" : "option"].size }}</span>
                </md-table-cell>
            </md-table-row>
        </template>
        <md-table-row>
          <md-table-cell md-label="ID" class="custom-field">
            <span>Единиц товара: </span>
          </md-table-cell>
          <md-table-cell md-label="ID" class="custom-field">
            <span>{{ item.quantity }}</span>
          </md-table-cell>
      </md-table-row>
    </md-table>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ProductCell",
  props: {
    item: Object,
  },
  data() {
    return {
      color: null,
    }
  },
  methods: {
    ...mapActions("returnPage", [ "getColor" ]),
    colorName(id) {
      this.$store.commit("SET_SHOW_LOADER", true);


      this.getColor(id).then((res) => {
        this.color = res;
      })

      this.$store.commit("SET_SHOW_LOADER", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-field img{
  width: 100px;
  height: auto;
  max-height: 100px;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}
</style>

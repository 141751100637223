<template>
  <div
      class="product_card"
      @click="$emit('toggleActive', product)"
  >
      <div
          class="product_card-image_box"
          v-for="image in product.images">
          <img :src="image.original_url" alt="product image" />
      </div>
      <div
        class="product_card-info"
      >
          <p>
               ID: {{ product.id }}
          </p>
          <p
            v-if="product.brand && product.brand.name"
          >
              Brand: {{ product.brand.name }}
          </p>
          <p>
              Категория: {{ product.name }}
          </p>
      </div>
      <div
              class="product_card-info"
      >
          <p>
              Цена: {{ product.price }}
          </p>
          <p>
              Старая цена: {{ product.old_price }}
          </p>
          <p>
              Дата обновления: {{ formatISODate(product.update_at) }}
          </p>
      </div>
  </div>
</template>

<script>
import { formatISODate } from "@/helpers/formatDate";
export default {
    name: "ProductCard",
    props: {
        product: Object,
    },
    methods: {
        formatISODate,
    },
}
</script>

<style lang="scss" scoped>
.product_card {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  &:hover {
    background: rgba(black, .05);
  }
  &-image_box {
    flex: 1;
    width: fit-content;
    img {
      width: 50px;
      height: 100%;
      max-height: 100px;
      border-radius: 4px;
    }
  }
  &-info {
    flex: 2;
  }
}
.active {
    background: rgba(black, .03);
}
</style>
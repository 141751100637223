<template>
  <div class="message-pane">
    <div class="message-pane-controls">
      <div class="message-pane-textarea-wrap">
        <textarea
          v-model="msg"
          class="message-pane-textarea"
          placeholder="Введите сообщение..."
          :rows="textareaRows"
          @keyup.enter.exact="send"
        />

        <div class="message-pane-icon-attach" @click="openFileSelectWindow" />

        <div v-if="files.length" class="message-pane-attached-files">
          <div class="message-pane-attached-files-wrap">
            <ChatAttachedFile
              v-for="(file, index) in files"
              :key="`file_${index}_${file.name}`"
              :file="file"
              @remove="removeFile(index)"
            />
          </div>
        </div>
      </div>
      <input
        ref="files"
        class="files-input"
        multiple
        type="file"
        @change="onChangeFiles"
      />
    </div>

    <div
      :class="['message-pane-btn-send', { disabled: isSending }]"
      @click="send"
    >
      Send
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import ChatAttachedFile from "./ChatAttachedFile";

export default {
  components: {
    ChatAttachedFile,
  },

  data() {
    return {
      msg: "",
      files: [],
      isSending: false
    };
  },

  computed: {
    textareaRows() {
      const rows = Math.ceil(this.msg.length / 69);
      if (rows < 1) {
        return 1;
      } else if (rows > 3) {
        return 3;
      } else {
        return rows;
      }
    },
  },

  methods: {
    ...mapActions("chats", ["sendMessage"]),

    async send() {
      /* eslint-disable-next-line no-undef */
      try {
        this.$store.commit("SET_SHOW_LOADER", true);
        const message = {
          id: this.$route.params.id,
          message: this.msg,
          files: this.files,
        };
        await this.sendMessage(message);
        this.$store.commit("SET_SHOW_LOADER", false);
        this.files = [];
        this.msg = "";
      } catch (e) {
        console.error(e);
      }
    },

    openFileSelectWindow() {
      this.$refs.files.click();
    },

    onChangeFiles() {
      let fileList = this.$refs.files.files;
      Array.from(fileList).forEach((file) => {
        this.files.push(file);
      });
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.message-pane {
  display: flex;

  &-controls {
    flex: 1;
  }

  &-textarea {
    display: block;
    width: 100%;
    resize: none;
    border: none;
    outline: none;
    appearance: none;
    line-height: 16px;
    color: #1a1f21;

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-ms-input-placeholder,
    &:-ms-input-placeholder {
      opacity: 0.5;
    }

    &:focus {
      outline: none !important;
    }

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
      background-color: #f7f7f7;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      border: 5px solid #cee8f5;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #cee8f5;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &-wrap {
      position: relative;
      margin-right: 10px;
      padding: 10px 36px 9px 21px;
      background: #ffffff;
      border-radius: 3px;
    }
  }

  &-btn-send {
    width: 51px;
    height: 35px;
    border-radius: 3px;
    padding: 9px 9px 6px 5px;
    background-color: green;
    cursor: pointer;
    text-align: center;
    color: #FFFFFF;

    &.disabled {
      pointer-events: none;
    }

    &-icon {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

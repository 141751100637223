<template>
  <section class="chat-section">
    <div
      v-if="showMessages"
      class="chat-section-wrap"
    >
      <ul class="chat mb">
        <li v-for="(message, i) in messages" :key="i">
          <ChatMessage
            :is-first="i === 0"
            :message="message"
          />
        </li>
      </ul>
      <ChatMessagePane />
    </div>

    <div v-else class="empty-section-wrap">
      Выберите один из контактов
    </div>
  </section>
</template>

<script>
import ChatMessage from "./ChatMessage";
import ChatMessagePane from "./ChatMessagePane";
import {mapState} from "vuex";

export default {
  components: {
    ChatMessage,
    ChatMessagePane,
  },

  props: {
    contactActive: {
      type: Object,
      default: () => ({}),
    },

    tab: {
      type: String,
      default: "",
    },
  },
    data() {
    return {
      mediaForModal: null,
    }
  },
    computed: {
    ...mapState("chats", ["messages"]),
    // ...mapState("chat", ["contactActive"]),

    isShowDateSeparator() {
      return 1;
      // return (message, index) => {
      //   if (index == 0) {
      //     return true;
      //   } else {
      //     const getDate = function (message) {
      //       if (message.clientDate) {
      //         return getRightDate(message.date, true).toLocaleDateString();
      //       } else {
      //         return message.date.split(" ")[0];
      //       }
      //     };
      //
      //     let firstDate = getDate(message),
      //       secondDate = getDate(this.messages[index - 1]);
      //
      //     return firstDate !== secondDate;
      //   }
      // };
    },

    isShowTimeSeparator() {
      return (message, index) => {
        if (
          index === 0 ||
          message.senderId !== this.messages[index - 1].senderId
        ) {
          return true;
        } else {
          const firstTime = message.clientDate || message.time;
          const secondTime =
              this.messages[index - 1].clientDate ||
              this.messages[index - 1].time;

          return firstTime !== secondTime;
        }
      };
    },

    mainLotChat() {
      return !!(this.lotActive && !this.contactActive);
    },

    newDialogsCount() {
      return this.lotActive?.new_dialogs_count || 0;
    },

    nextHasSameSender() {
      return (message, index) => {
        return (
          index !== this.messages.length - 1 &&
          message.senderId === this.messages[index + 1].senderId
        );
      };
    },

    showMessages() {
      return !!this.contactActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-section {
  flex: 1;

  .contact-mobile {
    display: none;
  }

  &::v-deep {
    .modal {
      width: unset !important;
      max-width: 1000px !important;
      height: unset !important;
      max-height: 560px !important;
    }
  }

  &-header {
    overflow: hidden;
    border-radius: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin: 0 -4px;

    &-info {
      background-color: #229ed4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 10px;
      height: 50px;
    }

    &-id {
      flex: 1 0 33%;
    }

    &-title {
      flex: 1 0 34%;
      font-weight: 700;
      text-align: center;
    }

    &-dialogs {
      flex: 1 0 33%;

      &-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &-count {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 9px;
        height: 22px;
        border-radius: 3px;
        padding: 3px;
        background-color: #ffffff;
        color: #229ed4;
      }
    }

    &-message {
      text-align: center;
      padding: 15px 10px 27px;
      color: #3f6271;
    }
  }

  &-wrap {
    height: 680px;
    display: flex;
    flex-direction: column;
    background-color: #e9f4f9;
    padding: 0 4px 5px;
    border-radius: 3px;

    &.not-lot-messages {
      padding-top: 16px;
    }
  }

  .empty-section-wrap {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    background-color: #e9f4f9;
  }
}

.chat {
  overflow: auto;
  padding: 10px;
  flex: 1;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 5px solid #cee8f5;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #cee8f5;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.mobile-messages {
  align-items: center;

  .back-arrow {
    cursor: pointer;
    height: 20px;
    margin-right: 30px;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    width: 148px;
  }

  .user-name {
    color: black;
    font-weight: 700;
    padding-top: 5px;
    margin-bottom: 10px;
  }

  .user-company {
    color: #1a1f21;
    font-size: 14px;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    width: 148px;
  }

  .user-name {
    color: black;
    font-weight: 700;
    padding-top: 5px;
    margin-bottom: 10px;
  }

  .user-company {
    color: #1a1f21;
    font-size: 14px;
  }
}
</style>

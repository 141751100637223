<template>
  <transition name="modal-fade">
    <div ref="modal" class="modal-backdrop" @click="clickOutside">
      <div class="modal" role="dialog">
        <div
          class="btn-close"
          @click="close"
        >
          <md-icon class="btn-close-icon">close</md-icon>
        </div>
        <div class="modal-img">
          <img :src="image" alt="review-img">
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showSpinner: {
      type: Boolean,
      default: false
    },

    image: {
      type: String,
      default: "",
    }
  },

  methods: {
    clickOutside(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.$emit("close");
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;

  .modal {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;

    &-img {
      max-width: 1000px;
      max-height: 800px;
    }
  }

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;

    &-icon {
      color: red;
      font-size: 40px !important;
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.buttons-wrap {
  display: flex;

  & > * {
    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}
.buttons-wrap {
  display: flex;
  align-items: center;
}
.spin {
  margin: 0 10px;
}
</style>

<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card
        v-if="currentChat.order"
      >
          <md-card-header data-background-color="blue" class="card-header">
              <div class="card-header-info">
                  <h4 class="title">Товары:</h4>
              </div>
          </md-card-header>
          <md-card-content>
              <ProductCell
                v-for="item in currentChat.order.order_product"
                :item="item"
              />
              <p
                v-if="!currentChat.order.order_product.length"
              >
                Товары отсутствуют
              </p>
          </md-card-content>
      </md-card>
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Чат</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="editBrandForm" :slim="true">
          <md-card-content>
            <Chat />
            <div class="buttons-wrap">
                <md-button
                        class="md-primary"
                        @click="showProductModal = true"
                >
                    Прикрепить товар
                </md-button>
              <md-button
                class="md-raised md-danger"
                @click="$router.push('/chats')"
              >
                Назад
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
      <ModalProduct
        :id="$route.params.id"
        v-if="showProductModal"
        @close="showProductModal = false"
      />
  </div>
</template>

<script>
import ModalProduct from "@/components/Modals/AddProduct.vue";
import Chat from "@/components/Chat/index";
import {mapActions} from "vuex";
import ProductCell from "@/pages/ReturnOrder/ProductCell.vue";

export default {
  components: {
      ProductCell,
    Chat,
    ModalProduct,
  },

  data() {
    return {
      currentChat: {},
      showProductModal: false,
    };
  },

  async mounted() {
    try {
      this.$store.commit("SET_SHOW_LOADER", true);
      /* eslint-disable-next-line no-undef */
      await axios.get(`/api/admin/refund-chat/${this.$route.params.id}`)
        .then((res) => this.currentChat = res.data)
      /* eslint-disable-next-line no-undef */
      await this.getChatMessages(this.$route.params.id);
      this.$store.commit("SET_SHOW_LOADER", false);
    } catch (e) {
      console.error(e);
    }
  },

  methods: {
    ...mapActions("chats", ["getChatMessages"]),
  }
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
